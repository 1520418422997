export default function Social(){
    return(
        <div className="home__social">
            <a href="https://facebook.com/muhibuwp" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-facebook"></i>
            </a>
            
            <a href="https://github.com/devmuhib009" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-github"></i>
            </a>

            <a href="https://profiles.wordpress.org/devmuhib/" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-wordpress"></i>
            </a>

            <a href="https://www.youtube.com/@dev-muhib" className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-youtube"></i>
            </a>

        </div>
    )
}