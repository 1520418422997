export default function Info(){
    return(
        <div className="about__info grid">

            <div className="about__box">
                <i className='bx bx-award'></i>
                <h3 className="about__title">Experience</h3>
                <span className="about__subtitle">8 Years Working</span>
            </div>

            <div className="about__box">
                <i className="uil uil-briefcase"></i>
                <h3 className="about__title">Completed</h3>
                <span className="about__subtitle">48+ Project Completed</span>
            </div>

            <div className="about__box">
                <i className='bx bx-support' ></i>
                <h3 className="about__title">Support</h3>
                <span className="about__subtitle">24/7 Support</span>
            </div>
        </div>
    )
}