import { useState } from 'react'
import './services.css'
export default function Services(){
    const [showModal, setShowModal] = useState(0);
    const activeModal = (curr) => {
        setShowModal(curr);
    }
    return(
        <section className="services section" id="services">
            <h2 className="section__title">
                Services
            </h2>
            <span className="section__subtitle">
                What I Offer
            </span>

            <div className="services__container container grid">
                <div className="services__content">
                    <div>
                        <i className="uil uil-web-grid services__icon"></i>
                        <h3 className="services__title">
                            WordPress <br /> Development
                        </h3>

                        <span className="services__button" onClick={() => activeModal(1)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

                        <div className={showModal === 1 ? "services__modal active-modal" : "services__modal"}>
                            <div className="services__modal-content">
                                <i onClick={() => setShowModal(0)} className="uil uil-times services__modal-close"></i>
                                <h3 className="services__modal-title">
                                    WordPress Development
                                </h3>
                                <p className="services__modal-description">
                                I specialize in WordPress development, creating dynamic and engaging websites tailored to meet your specific needs. With a focus on functionality and user experience, I ensure that each project enhances your brand’s online presence.
                                </p>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            I design custom themes that reflect your brand identity.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Plugin development for added functionality and features.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Optimization for speed and performance improvements.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Integration with third-party services and APIs.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Ongoing support and maintenance for a seamless experience.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className="uil uil-apps services__icon"></i>
                        <h3 className="services__title">
                            MERN Stack <br /> Development
                        </h3>

                        <span className="services__button" onClick={() => activeModal(2)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

                        <div className={showModal === 2 ? "services__modal active-modal" : "services__modal"}>
                            <div className="services__modal-content">
                                <i onClick={() => setShowModal(0)} className="uil uil-times services__modal-close"></i>
                                <h3 className="services__modal-title">
                                    MERN Stack Development
                                </h3>
                                <p className="services__modal-description">
                                    I specialize in MERN stack development, leveraging the power of MongoDB, Express.js, React, and Node.js to build robust and scalable web applications. My focus is on creating seamless user experiences and efficient server-side solutions.
                                </p>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            I develop dynamic single-page applications (SPAs) using React.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            RESTful APIs for efficient data exchange between client and server.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Database design and management with MongoDB for flexibility and scalability.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Implementing real-time features using WebSockets for enhanced interactivity.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Comprehensive testing and debugging to ensure high-quality performance.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services__content">
                    <div>
                        <i className='bx bxs-megaphone services__icon'></i>
                        <h3 className="services__title">
                            Brand <br /> Promotion
                        </h3>

                        <span className="services__button" onClick={() => activeModal(3)}>View More <i className="uil uil-arrow-right services__button-icon"></i></span>

                        <div className={showModal === 3 ? "services__modal active-modal" : "services__modal"}>
                            <div className="services__modal-content">
                                <i onClick={() => setShowModal(0)} className="uil uil-times services__modal-close"></i>
                                <h3 className="services__modal-title">
                                    Brand Promotion
                                </h3>
                                <p className="services__modal-description">
                                    I utilize my YouTube channel as a powerful platform for brand promotion, helping businesses reach a wider audience and enhance their online presence. Through engaging content and strategic marketing techniques, I effectively showcase brands and their unique offerings.
                                </p>
                                <ul className="services__modal-services grid">
                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            I create high-quality video content that highlights brand features and benefits.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Collaborations with brands to develop authentic promotional campaigns.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Audience engagement strategies to foster community and brand loyalty.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Data-driven insights to measure the impact of promotional efforts.
                                        </p>
                                    </li>

                                    <li className="services__modal-service">
                                        <i className="uil uil-check-circle services__modal-icon"></i>
                                        <p className="services__modal-info">
                                            Consistent branding and messaging across all video content to reinforce brand identity.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}