import Uttara from "../../assets/uttara.png";
import PizzaKurier from "../../assets/pizzakurier.png";
import Bottola from "../../assets/bottola.png";
import Realstar from "../../assets/realstar.png";
import Tecognize from "../../assets/tecognize.png";
import Linuxpathshala from "../../assets/linuxpathshala.png";
import Reactquiz from "../../assets/reactquiz.png";
import Movierating from "../../assets/movierating.png";
import Currencyconverter from "../../assets/currencyconverter.png";
import Trainingteam from "../../assets/trainingteam.png";
import Mentorship from "../../assets/mentorship.jpg";
import SylhettableLead from "../../assets/wordcamptablelead.png";

export const projectsData = [
    {
      id: 1,
      image: Uttara,
      title: "Uttara Restaurant",
      category: "wordpress",
      link: "https://uttararestaurant.com/"
    },
    {
      id: 2,
      image: PizzaKurier,
      title: "Pizza Kurier",
      category: "wordpress",
      link: "https://pizzakurier24.de/"
    },
    {
      id: 3,
      image: Bottola,
      title: "Bottola Cyber Net",
      category: "wordpress",
      link: "https://btcnbd.com/"
    },
    {
      id: 4,
      image: Realstar,
      title: "Real Star Properties",
      category: "wordpress",
      link: "https://realstarpl.com/"
    },
    {
      id: 5,
      image: Tecognize,
      title: "Web design",
      category: "wordpress",
      link: 'https://tecognize.com/'
    },
    {
      id: 6,
      image: Linuxpathshala,
      title: "Linux Pathshala Ltd.",
      category: "wordpress",
      link: 'http://linuxpathshala.com/'
    },
    {
      id: 7,
      image: Reactquiz,
      title: "React Quiz Practice Project",
      category: "react",
      link: 'https://github.com/devmuhib009/react-quiz-project'
    },
    {
      id: 8,
      image: Movierating,
      title: "Movie Rating Practice Project",
      category: "react",
      link: 'https://github.com/devmuhib009/movie-rating'
    },
    {
      id: 9,
      image: Currencyconverter,
      title: "Currency Converter Practice",
      category: "react",
      link: 'https://github.com/devmuhib009/Currency-Converter-React'
    },
    {
      id: 10,
      image: Trainingteam,
      title: "Training Team Guide",
      category: "wordpress contribution",
      link: 'https://make.wordpress.org/training/handbook/guide-program/#current-guides'
    },
    {
      id: 11,
      image: Mentorship,
      title: "WordPress Mentorship Program",
      category: "wordpress contribution",
      link: 'https://make.wordpress.org/community/2024/02/16/contributor-mentorship-program-second-cohort-2024-q1/'
    },
    {
      id: 12,
      image: SylhettableLead,
      title: "WordCamp Table Lead",
      category: "wordpress contribution",
      link: 'https://sylhet.wordcamp.org/2024/contributor-day-table-leads/'
    },
  ];


export const projectsNav = [
    {
        name: "all"
    },
    {
        name: "wordpress"
    },
    {
        name: "react"
    },
    {
        name: "wordpress contribution"
    }
]