import { useState } from 'react';
import './qualifications.css';
export default function Qualifications(){

    const [showQuality, setShowQuality] = useState(1);
    const activeQuality = (curr) => {
        setShowQuality(curr);
    }
    return(
        <section className="qualification section">
            <h2 className="section__title">Qualifications</h2>
            <span className="section__subtitle">
                My Personal Journey!
            </span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div className={showQuality === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => activeQuality(1)}>
                        <i className="uil uil-graduation-cap qualification__icon"></i> Education
                    </div>

                    <div className={showQuality === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => activeQuality(2)}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                    </div>
                </div>

                <div className="qualification__sections">

                    <div className={showQuality === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    B.Sc. in Computer Science
                                </h3>
                                <span className="qualification__subtitle">
                                    Prime University
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i> 
                                    &nbsp; 2014
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">

                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">
                                    Diploma in Hardware & Networking
                                </h3>
                                <span className="qualification__subtitle">
                                    Daffodil International Professional Training Institute (DIPTI)
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; 2017
                                </div>
                            </div>

                        </div>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Higher Secondary School Certificate
                                </h3>
                                <span className="qualification__subtitle">
                                    Govt. Gurudayal College, Kishoreganj
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; 2009
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">

                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">
                                    Secondary School Certificate
                                </h3>
                                <span className="qualification__subtitle">
                                    Kishoreganj Govt. Boys High School
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; 2007
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={showQuality === 2 ? "qualification__content qualification__content-active" : "qualification__content"} onClick={() => activeQuality(2)}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Web Developer
                                </h3>
                                <span className="qualification__subtitle">
                                    BuzzGeek, LLC
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; Feb 2024 - Present
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">

                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">
                                    Software Engineer
                                </h3>
                                <span className="qualification__subtitle">
                                    BD Themes Ltd.
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; Jan 2023 - Aug 2023
                                </div>
                            </div>

                        </div>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">
                                    Tecognize Solutions Limited
                                </h3>
                                <span className="qualification__subtitle">
                                    Web Developer
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    &nbsp; 2021 - 2023
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                        <div className="qualification__data">

                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">
                                    Opensource Contribution
                                </h3>
                                <span className="qualification__subtitle">
                                    WordPress
                                </span>
                                <div className="qualification__calender">
                                    <i className="uil uil-calendar-alt"></i>
                                    2023 - Present
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}