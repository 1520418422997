import './scrollup.css'
export default function ScrollUp(){

    window.addEventListener("scroll", function() {
        const scrollUp = document.querySelector(".scrollup");
        
        if (scrollUp) { // Add null check to prevent errors
            if (this.scrollY >= 560) {
                scrollUp.classList.add("show-scroll");
            } else {
                scrollUp.classList.remove("show-scroll");
            }
        }
    });

    return(
        <a href="#top" className="scrollup">
            <i className="uil uil-arrow-up scrollup__icon"></i>
        </a>
    )
}